.dashboard{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
}
.mainAdmin{
    background-color: #f7f7f7;
    width: calc(100% - 250px);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loadpage{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
}
.loadpage img{
    width: 100px;
}

.process{
    display: flex;
    flex-direction: column;
    width: calc(100% - 250px);
}

.title-admin{
    width: 100%;    
    background-color: #111111;
    background-color: #201f20;
    color: #f7f7f7;
    text-align: center;
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
}


.opacity{
    opacity: 0;
}

.linkToSite{
    color: #eeeeee;
    font-weight: 500;
    text-decoration: underline;
}


.my-cargo-flex2{
    display: flex;
    flex-direction: column;
    
}




























.my-cargo{
    display: flex;
    flex-direction: column;
    width: calc(100% - 250px);
}

.section-my-cargo{
    display: flex;
    align-items: flex-start;
    justify-content: center;
}




.status-list{
    width: 400px;
    display: flex;
    flex-direction: column;
    padding: 25px;
    border-radius: 10px;
    border: 1px solid rgba(129, 129, 129, 0.3);
    margin: 50px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);    
}
.status-list-title{
    font-size: 22px;
    font-weight: 500;
    text-align: center;

}

.statuses{
    margin-top: 10px;
}   

.status-el{
    padding: 15px;
    font-size: 16px;
    margin: 10px 0;
    border-radius: 10px;
    border: 1px solid rgba(129, 129, 129, 0.5);
    cursor: pointer;

    display: flex;
    justify-content: space-between;
    align-items: center;
}
.status-el:hover{
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);    

}

.trash{
    width: 20px;
}

.form-addTrack{
    margin: 20px 0 10px 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-radius: 10px;

}
.input-statusAdd{
    border: 1px solid rgba(129, 129, 129, 0.5);
    width: 100%;
    border-radius: 4px 0px 0px 4px;
    padding: 10px 15px;
    border-right: 0;

}

.input-statusAdd:focus{
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);    

}

.statusAdd-button{
    background-color: #4285F4;
    background-color: green;
    color: #FFFFFF;
    padding: 5px 15px;
    border: none;
    border-radius: 0 4px 4px 0;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    transition-duration:  0.3s;

}
.statusAdd-button:hover{
    background-color: green;
    background-color: #4285F4;

}

/* filial */

.form-filialAdd{
    display: flex;
    flex-direction: column;
    margin-top: 10px;

}

.inputs-container-filial{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.input-filialAdd{
    border: 1px solid rgba(129, 129, 129, 0.5);
    width: 100%;
    border-radius: 4px ;
    padding: 10px 15px;
    margin: 5px 0;
}
.input-filialAdd:focus{
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);    

}

.filialAdd-button{
    margin-top: 10px;
    background-color: #4285F4;
    background-color: green;
    color: #FFFFFF;
    padding: 10px;
    border: none;
    border-radius:4px;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    transition-duration:  0.3s;
}
.filialAdd-button:hover{
    background-color: #4285F4;

}
.filial-el{
    padding: 15px;
    font-size: 16px;
    margin: 10px 0;
    border-radius: 10px;
    border: 1px solid rgba(129, 129, 129, 0.5);
    cursor: pointer;

    display: flex;
    justify-content: space-between;
    align-items: center;
}





/* AddTrack */
.mainAdmin-container{
    padding: 30px;
    display: flex;
    flex-direction: column;
    margin: 30px 0 0 0;
    
}
.mini-contianer{
    display: flex;
    
}
.status-block {
    cursor: pointer;
    border: 1px solid #ccc;
    padding: 15px 20px;
    border-radius: 10px; /* Закругление углов */
    text-align: center;
    margin: 20px 0;
    color: rgb(255, 255, 255);
    color: #111111;
    font-size: 16px;
    font-weight: 500;
    background-color: rgb(96, 146, 96); /* Цвет фона */
    background-color: #ffffff; /* Цвет фона */
  

    display: flex;
    align-items: center;
    justify-content: space-between;


}
.status-block:hover {
    border-color: #007bff; /* Цвет границы при наведении */
    transform: scale(1.03);
}
.status-block p{
    margin: 0 5px;
}
.status-block img{
    width: 25px;
    margin: 0 5px;
}
.excel-block{
    cursor: pointer;
    border: 1px solid #ccc;
    padding: 15px 20px;
    border-radius: 10px; /* Закругление углов */
    text-align: center;
    margin: 20px 0;
    color: #111111;
    font-size: 16px;
    font-weight: 500;
    background-color: #fff;
    
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.excel-block:hover {
    border-color: #007bff; /* Цвет границы при наведении */
    transform: scale(1.03);
}

.excel-block p{
    margin: 0 5px;
}
.excel-block img{
    width: 25px;
    margin: 0 5px;
}


.date-container{
    margin: 0px 20px ;
    display: flex;
    flex-direction: column;
}
.h3-date{
    font-size: 16px;
    margin: 0 0 10px 0;
    text-align: center;
    font-weight: 500;
}
.date-block {
    width: 220px; /* Ширина Select по размеру контейнера */
    cursor: pointer;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 4px;
    text-align: center;

}
  
.wight-block{
    width: 220px; /* Ширина Select по размеру контейнера */
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 4px;
    margin: 20px 0 0 0;
}

.place-parcel{
    margin: 10px 0 0 0;

}

.scaner-block{
    width: 100%;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 10px; /* Закругление углов */
    text-align: center;
    margin: 20px 0;
    color: #111111;
    font-size: 16px;
    font-weight: 500;
    background-color: #fff;
    
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.scaner-block:hover {
    border-color: #007bff; /* Цвет границы при наведении */
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
}

.scaner-block p{
    margin: 0 5px;
}
.scaner-block img{
    width: 25px;
    margin: 0  20px 0 0;

}

.scaner-input{
    padding: 15px 20px;
    border-radius: 10px; /* Закругление углов */
    width: 100%;
}
.scaner-input::placeholder{
    color: #111111;
}


.scaner-block.focus {
    border-color: #007bff;
}

.status-pop:hover{
    background-color: #fff;
}


.modalExcel{
    position: fixed;
    top: 40px;
    left: 280px;
    background-color: #fff;
    width: 75%;
    height: 80%;

    border: 1px solid #ccc;
    padding: 20px 30px 40px 30px;
    border-radius: 10px; /* Закругление углов */
    text-align: center;
    margin: 20px 0;
    color: #111111;
    background-color: #ffffff; /* Цвет фона */
    border-color: #007bff; /* Цвет границы при наведении */
  

    display: flex;
    flex-direction: column;
}
.modal-header{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.modal-header h2{
    font-size: 23px;
    font-weight: 500;
    color: #303030;

}
.close{
    width: 20px;
    height: 20px;
    background-image: url('../../../assets/img/closeA.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    transition-duration: 0.3s;
}

.close:hover{
    background-image: url('../../../assets/img/closeB.png');

}




.textarea{
    width: 100%;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 10px; /* Закругление углов */
    margin: 30px 0 0 0;
    color: #111111;
    font-size: 16px;
    font-weight: 500;
    background-color: #fff;
    padding: 20px;
    height: 100%;

}
.textarea:hover {
    border-color: #007bff; /* Цвет границы при наведении */
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
}




.buttonExcel{
    margin-top: 10px;
    background-color: #4285F4;
    background-color: green;
    color: #FFFFFF;
    padding: 10px;
    border: none;
    border-radius:4px;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    transition-duration:  0.3s;
}
.buttonExcel:hover{
    background-color: #4285F4;

}


.modal-load{
    z-index: 10000;
    position: absolute;
    top: 30%;
    left: 40%;
    width: 225px;
    height: 100px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 10px; /* Закругление углов */
    display: flex;
    align-items: center;
    justify-content: center;
}
.modal-load img{
    width: 30px;
    
  }

.modal-load p{
    font-weight: 500;
    margin: 0 0 0 10px;
}

.loading img{
    display: inline-block;
    animation: rotate 2s linear infinite;
    
  }
@keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }




  /* UserList */


.users-container{
    width: 100%;
    height: 100%;

    padding: 20px 5px;

}

.header-bar{
    border-radius: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    background-color: #ffffff; /* Цвет фона */
    
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);    

}

.search-bar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f8f8f8; /* Цвет фона */
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    cursor:text;

}

.search-bar:hover{
    border-color: #007bff; /* Цвет границы при наведении */

}
.searchIcon{
    width: 17px;
}
.searchInput{
    width: 150px;
    background-color: #f8f8f8; /* Цвет фона */
    margin-left: 10px;
}
.filter-bar{
    display: flex;
    align-items: center;

}

.filter-point{
    background-color: #f1f1f1; /* Цвет фона */
    border: 1px solid #ccc;
    padding: 11px;
    border-radius: 5px;
    cursor:pointer;
    font-size: 14px;
    margin-left: 15px;
    transition-duration: 0.3s;
}

.filter-point:hover{
    border-color: #007bff; /* Цвет границы при наведении */
    background-color: #fff;
}
.filter-point-active{
    background-color: #fff;
    border-color: #007bff; /* Цвет границы при наведении */

}

.status-filter{
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative; 
}

.statuses-popup {
    position: absolute;
    top: 100%; /* Position it below the filter-point */
    left: 20px;
    background-color: white;
    border: 1px solid #ccc;
    padding: 5px 5px 0 5px;
    z-index: 1000;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    border-radius: 5px;
}

.filter-point-status{
    background-color: #f1f1f1; /* Цвет фона */
    border: 1px solid #ccc;
    padding: 11px;
    border-radius: 5px;
    cursor:pointer;
    font-size: 14px;
    transition-duration: 0.3s;
    margin: 0 0 5px 0 ;
}


/*  */
.page-point{
    background-color: #f1f1f1; /* Цвет фона */
    border: 1px solid #ccc;
    padding: 5px 8px;
    border-radius: 5px;
    cursor:pointer;
    font-size: 12px;
    margin-left: 20px;
    transition-duration: 0.3s;
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.page-point:hover{
    border-color: #007bff; /* Цвет границы при наведении */
}

.page-point input{
    width: 50px;
    padding: 5px;
    margin-left: 5px;
    border-radius: 5px;
    background-color: #fcfcfc;
    border: 1px solid #ccc;
}

.page-point input:focus{
    border-color: #007bff; /* Цвет границы при наведении */
}

.table-user{
    margin: 10px 0 0 0;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: #ffffff; /* Цвет фона */
    padding: 5px;
}


.table {
    width: 100%;
    border-collapse: collapse;

}
  
.table th, .table td {
    border: 1px solid #ccc;
    padding: 8px;
}

.table thead {
    background-color: #EEEEEE;
}

/* settings */

.inputs-wrapper{
    padding: 20px 0 0 0;
    
}

.settingsGet{
    padding: 20px;
    background-color: #f3f3f3;
    border-radius: 4px;
    white-space: pre-wrap;

}


.date-container_mobile{
    display: none;
}

.page-point-bar{
    margin-top: 20px ;
    display: flex;
    justify-content: center;
}


.inputs-wrapper-price{
    padding: 10px  0;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.inputs-wrapper-contacts{
    margin-top: 20px;
}

.miniButton-container{
    display: flex;
    
}

.miniButton{
    padding: 12px;
    background-color: rgb(195, 208, 228);
    margin-left: 10px;
    border-radius: 7px;
    border: 1px solid  rgb(195, 208, 228);
    cursor: pointer;
}

.miniButton:hover{
    background-color: rgb(195, 208, 228);
    border: 1px solid rgb(157, 168, 185) ;
}

.miniButton-active{
    background-color: rgb(64, 151, 64);
    color: #fff;
    border: 1px solid rgb(64, 151, 64) ;
}

.miniButton-active:hover{
    background-color: rgb(64, 151, 64);
    border: 1px solid rgb(64, 151, 64) ;
}

.priceCurrency{
    padding: 0 0 0 10px;
    background-color: #f3f3f3;

}



  .table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .table th,
  .table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .table th {
    background-color: #f2f2f2;
  }
  
  .table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .table tbody tr:hover {
    background-color: #ddd;
    cursor: pointer;
  }
  
.tdUserTrack{
    padding: 4px 10px;
    background-color: #99fdbb;
    border-radius: 5px;
}


.tdUserTrack.user-unknown {
    background-color: rgba(255, 255, 255, 0);
}


.totalCount{
    margin: 10px 0 0 15px;
    padding: 10px;
    background-color: #e9e9e9;
    border-radius: 10px;
    font-size: 12px;
    width: 200px;
    text-align: center;
}


.add-lost-item-button {
    margin-left: 12px; /* Отступ от поля поиска */
    padding: 10px 16px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
}

.add-lost-item-button:hover {
    background-color: #0056b3;
}

.buttonExcelLost{
    background-color: #007bff;

}

.buttonExcelLost:hover{
    background-color: #007bff;

}

.header-bar-client{
    margin-top: 10px;
    padding: 5px;
}
.search-bar-client{
    width: 100%;
    border-radius: 0;
    border-radius: 6px;
}

.searchInput-client{
    width: 100%;
}
.totalCount-client{
    width: 100%;
    margin: 10px 0 0 0;
}
.page-point-bar-client{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.page-point-client{
    margin: 0;
}
.page-point-client2{
    margin: 0;
    padding: 15px;
}


/* bonus */
/* Основные стили для таблицы бонусов */
.bonus-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
}

.bonus-table th, .bonus-table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: center;
}

.bonus-table th {
    background-color: #f4f4f4;
    font-weight: bold;
}

/* Стили для кнопок в таблице */
.bonus-table button {
    background-color: #007bff; /* Основной цвет кнопок */
    color: white; /* Цвет текста */
    border: none; /* Убираем границу */
    border-radius: 4px; /* Закругленные углы */
    padding: 8px 12px; /* Отступы */
    cursor: pointer; /* Курсор в виде руки */
    font-size: 14px; /* Размер шрифта */
    transition: background-color 0.3s, transform 0.2s; /* Плавные переходы */
}

.bonus-table button:hover {
    background-color: #0056b3; /* Цвет при наведении */
}

.bonus-table button:active {
    transform: scale(0.98); /* Уменьшаем кнопку при нажатии */
}

.referrals-section {
    margin-top: 20px;
}

.referrals-section ul {
    list-style-type: none;
    padding: 0;
}



.modal-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Обеспечивает, что модальное окно находится поверх других элементов */
}

.modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 600px;
    position: relative;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: #f00;
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
}

.partner{
    padding: 30px;
}



/* Стили для формы поиска */
.search-form {
    display: flex;
    align-items: center;
    margin-bottom: 20px; /* Отступ снизу для отделения от таблицы */
}

.search-form input {
    padding: 8px 12px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    flex: 1; /* Поле ввода занимает все доступное пространство */
    margin-right: 10px; /* Отступ справа от поля ввода */
}

.search-form button {
    padding: 8px 12px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff; /* Синий цвет кнопки */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.search-form button:hover {
    background-color: #0056b3; /* Темнее синий при наведении */
}

.search-form button:focus {
    outline: none; /* Убираем обводку при фокусе */
}


.title-percent{
    margin-top: 45px;
    font-size: 22px;
    font-weight: 500;
    text-align: center;

}


/* баннеры */
.banner-list{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 25px;
    border-radius: 10px;
    border: 1px solid rgba(129, 129, 129, 0.3);
    margin: 50px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);    
}

.banners {
    width: 100%;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: scroll; /* Горизонтальный скролл */
    white-space: nowrap; /* Запрещает перенос элементов на новую строку */
}

.banner {
    width: 140px;
    height: 90px;
    background-color: #fff;
    border-radius: 10px;
    margin-right: 15px;
    flex-shrink: 0; /* Предотвращает изменение размера баннеров */
    scroll-snap-align: start; /* Привязывает баннер к началу при скролле */
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.05);
    border-bottom: 1px solid rgba(134, 134, 134, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; /* Указатель при наведении */
}

.banner img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Сохранение пропорций изображения */
    border-radius: 10px;
}

.banner span {
    font-size: 14px;
    color: #aaa;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-user {
    background: #ffffff;
    padding: 30px;
    border-radius: 12px;
    width: 450px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    font-family: Arial, sans-serif;
  }
  
  .modal-fields {
    margin-bottom: 20px;
  }
  
  .modal-field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .modal-field label {
    font-weight: bold;
    flex-basis: 40%;
  }
  
  .modal-field span,
  .modal-field input {
    flex-basis: 55%;
  }
  
  .modal-field input {
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 6px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .modal-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .btn {
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 8px;
    cursor: pointer;
    border: none;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .save-btn {
    background-color: #28a745;
    color: #ffffff;
  }
  
  .save-btn:hover {
    background-color: #218838;
  }
  
  .cancel-btn {
    background-color: #dc3545;
    color: #ffffff;
  }
  
  .cancel-btn:hover {
    background-color: #c82333;
  }
  
  

  .edit-btn {
    padding: 4px 10px;
    font-size: 12px;
    border-radius: 6px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .edit-btn:hover {
    background-color: #0056b3;
  }
  
  .edit-btn:active {
    transform: scale(0.98);
  }
  


.tableheader{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 30px;
}

.tableheader button{
    padding: 5px 7px;
    border-radius: 5px;
}


/* Прячем Sidebar и показываем бургер-меню на мобильных устройствах */
@media (max-width: 768px) {
    
    .dashboard{
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: 0 auto;
    }
    .mainAdmin{
        background-color: #f7f7f7;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .dashboard > *:not(.mainAdmin) {
        display: none;
    }
    
    .title-admin{
        display: none;
    }

    .sidebar-mobile{
        
        display: flex;

    
    }
    
}












.custom-users-container {
    padding: 20px;
  }
  
  .custom-header-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .custom-search-bar {
    display: flex;
    align-items: center;
  }
  
  .custom-search-icon {
    margin-right: 10px;
  }
  
  .custom-search-input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .custom-filter-bar {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .custom-filter-point {
    cursor: pointer;
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .custom-filter-point-active {
    background-color: #007bff;
    color: white;
  }
  
  .custom-status-filter {
    position: relative;
  }
  
  .custom-statuses-popup {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    padding: 10px;
    z-index: 10;
  }
  
  .custom-filter-point-status {
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .custom-total-count {
    margin-top: 20px;
  }
  
  .custom-table-user {
    margin-top: 20px;
  }
  
  .custom-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .custom-table th,
  .custom-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  .custom-invoice-status {
    cursor: pointer;
    color: white; /* Белый цвет текста для общего стиля */
    padding: 5px;
    border-radius: 3px;
    display: inline-block;
  }
  
  .custom-invoice-status.custom-invoice-no-invoices {
    background-color: #808080; /* Серый фон для "Нет счетов" */
  }
  
  .custom-invoice-status.custom-invoice-paid {
    background-color: #4caf50; /* Зеленый фон для "Оплачено" */
  }
  
  .custom-invoice-status.custom-invoice-pending {
    background-color: #f44336; /* Красный фон для "Неоплачено" */
  }
  

  
  
  .custom-edit-btn {
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .custom-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .custom-modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 600px;
    width: 100%;
    height: 80%;
    overflow: scroll;
  }
  .header_custom_modal{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .custom-modal-fields {
    margin-top: 10px;
  }
  
  .custom-modal-field {
    margin-bottom: 15px;
  }
  
  .custom-modal-actions {
    display: flex;
    justify-content: space-between;
  }
  
  .custom-btn {
    padding: 10px 15px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
 
  
  .custom-invoice-list {
    margin-top: 20px;
  }
  
  .custom-invoice-card {
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .custom-invoice-pending {
    background-color: #f44336;
    color: white;
  }
  
  .custom-invoice-paid {
    background-color: #4caf50;
    color: white;
  }
  
  .custom-invoice-tracks {
    margin-top: 10px;
  }
  

  .custom-confirm-btn {
    padding: 8px 12px;
    background-color: #fff;
    color: #111111;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 10px;
  }
  
  .custom-confirm-btn:hover {
    background-color: #e4e4e4;
    color: #111111;
  }
  